import {BrowserRouter, Routes, Route } from 'react-router-dom';

//components
import Navbar from './components/Navbar';
//pages
import Home from './pages/Home';
import Services from './pages/Services';
import Clients from './pages/Clients';
import About from './pages/About';
import Contact from './pages/Contact';
/* import Login from './pages/Login'; */

//styles
import './App.css';

function App() {
  return (
    <div className="App">  
      <BrowserRouter>
      
        <div className='container'>
          <Navbar />
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/services' element={<Services />} /> 
            <Route path='/clients' element={<Clients />} /> 
            <Route path='/about' element={<About />} /> 
            <Route path='/contact' element={<Contact />} /> 
              {/* <Route path='/login' element={<Login />} />  */}
          </Routes>
        </div>  
      
      </BrowserRouter>
    </div>
  );
}

export default App;




// 