import React from 'react'


//styles
import "../css/Clients.css"

export default function Clients() {
  return (
    
    <div>
      <title>Clients</title>
      Clients
    </div>
  )
}
