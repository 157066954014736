import React from 'react'
import { useState } from 'react'

//firebase imports
import { firebaseDb } from '../firebase/config'
import { collection, addDoc } from 'firebase/firestore'


//style
import "../css/Contact.css"

export default function Contact() {
  const [ firstName, setFirstName ] = useState('')
  const [ lastName, setLastName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ description, setDescription ] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    await addDoc(collection(firebaseDb, 'contactMe'), {
      firstName: firstName,
      lastName: lastName,
      email: email,
      description: description
    })
  }
  return (
    <div className='contact'>
      <title>Contact</title>
      <h2>Have an idea or a question? <br></br>Lets chat!</h2>
      <form className='contact-form' onSubmit={handleSubmit}>
          <label>
             <span> First Name: </span>
             <input 
             required
             type="text"
             onChange={(e) => setFirstName(e.target.value)}
             value={firstName}
             />
          </label>          
          <label>
              <span> Last Name: </span>
              <input 
              required
              type="text"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              />
          </label>
          <label>
              <span> Email: </span>
              <input 
              required
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              />
          </label>
          <label>
              <span> Description: </span>
              <textarea 
              required
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              ></textarea>
            </label>
      <button className='btn'>Submit</button>
      </form>
      
      </div>
  )
}
