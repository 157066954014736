import { useNavigate } from 'react-router-dom'
/* import { useLogout } from '../hooks/useLogout' */
/* import { useAuthContext } from '../hooks/useAuthContext' */


//styles
import './Navbar.css'


export default function Navbar() {
    const navigate = useNavigate()
    /* const { logout, isPending } = useLogout() */

    return (
        <div className='navbar'>
            <ul>
                <li className='logo' onClick={() => navigate('/')}>
                    <img
                    src="https://firebasestorage.googleapis.com/v0/b/laptop-lifestyle-ec9c9.appspot.com/o/assets%2FLogo.png?alt=media&token=31a2b87b-dcf1-4030-b353-c641b081aed3"
                    alt="logo"
                    />
                </li>
                <li className='nav-items' onClick={() => navigate('/')}> Home </li> 
                <li className='nav-items' onClick={() => navigate('/services')}> Services </li> 
                <li className='nav-items' onClick={() => navigate('/clients')}> Clients </li> 
                <li className='nav-items' onClick={() => navigate('/about')}> About </li>
                <li className='nav-items' onClick={() => navigate('/contact')}> Contact </li>  
                {/* <li className='nav-items' onClick={() => navigate('/login')}> Login </li>  
                <li className='nav-items' onClick={() => navigate('/contact')}> Logout </li>  */} 
            </ul>
        </div>
    )
}


//
// https://firebasestorage.googleapis.com/v0/b/laptop-lifestyle-ec9c9.appspot.com/o/assets%2Fmenu-icon.png?alt=media&token=bc091574-27ef-4a15-8f73-62cd9845a906"
