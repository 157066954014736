import { initializeApp } from 'firebase/app';
import { getFirestore } from '@firebase/firestore';
import { getAuth } from 'firebase/auth';
//import { getStorage } from 'firebase/Storage';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDBFHIeAV7cFxPl6yMBa_z_R-AtllcO_XA",
    authDomain: "laptop-lifestyle-ec9c9.firebaseapp.com",
    projectId: "laptop-lifestyle-ec9c9",
    storageBucket: "laptop-lifestyle-ec9c9.appspot.com",
    messagingSenderId: "368432133037",
    appId: "1:368432133037:web:ada767677878de35b6a724",
    measurementId: "G-7ZEG1TWQKJ"
};

//Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Services
const firebaseDb = getFirestore(firebaseApp);
const firebaseAuth = getAuth();
//const firebaseStorage = getStorage();

export { firebaseDb, firebaseAuth } 
