import React from 'react'

//styles
import "../css/Services.css"

export default function Services() {
  return (
    <div className='services'>
      <title>Services</title>
      <div className='grid'>
        
        <div className='grid-items'>
          <div className='service-card'>
              <div className='service-card-photo'>
                <img
                src="https://firebasestorage.googleapis.com/v0/b/laptop-lifestyle-ec9c9.appspot.com/o/assets%2FWebdev%20Icon.png?alt=media&token=dfbd161c-7680-4b06-ad52-ed0ba1efca13"
                alt="webdev"
                />
              </div>
              <div className='service-card-info'>
                 <div className='service-card-text'>
                  <h3>Website Development</h3>
                  <p>Need a website and don't have time to figure out applications like Wordpress or Shopify?</p>
              </div>
              </div>
          </div>
        </div>

        <div className='grid-items'>
          <div className='service-card'>
              <div className='service-card-photo'>
                <img
                src="https://firebasestorage.googleapis.com/v0/b/laptop-lifestyle-ec9c9.appspot.com/o/assets%2FSEO.png?alt=media&token=bc5a715a-56eb-4aed-b9c3-f76e45a48c65"
                alt="SEO"
                />
              </div>
              <div className='service-card-info'>
                 <div className='service-card-text'>
                  <h3>Search Engine Optimization</h3>
                  <p>Do you have a website but want it to get more internet traction?</p>
                </div>
              </div>
          </div>
        </div>

        <div className='grid-items'>
            <div className='service-card'>
              <div className='service-card-photo'>
                  <img 
                  src="https://firebasestorage.googleapis.com/v0/b/laptop-lifestyle-ec9c9.appspot.com/o/assets%2FSocialMedia.png?alt=media&token=c936697c-d521-4405-b281-2a19e8ea3554"
                  alt="SocialMedia"
                  />
              </div>
              <div className='service-card-info'>
                <div className='service-card-text'>
                  <h3>Social Media Campaigns</h3>
                  <p>Approximately 70% of Americans use social media. Imagine being able to advertise to 70% of your population.</p>
                </div>
              </div>
            </div>
        </div>

        <div className='grid-items'>
            <div className='service-card'>
              <div className='service-card-photo'>
                  <img 
                  src="https://firebasestorage.googleapis.com/v0/b/laptop-lifestyle-ec9c9.appspot.com/o/assets%2FBusiness%20Integration.png?alt=media&token=50de02e2-0eb9-484c-a6f9-d842b6337ba9"
                  alt="business integration"
                  />
              </div>
              <div className='service-card-info'>
                <div className='service-card-text'>
                  <h3>Business Component Integration</h3>
                  <p>Better integrate the components of your business to allow your systems to talk to one another.</p>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>   
  )
  
  
}
