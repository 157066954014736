import React from 'react'

//styles
import '../css/Home.css'

export default function Home() {
  return (
    
    <div className='home-component'>
      <title>Home</title>
      <div>
         <div className='hero-statement'>
          <h3>Welcome to Laptop Lifestyle LLC</h3>
          <h1>IT Consulting Services for Small Businesses</h1>
          <p>-Utilizing technology so you can</p>
          <p className='bam'> Focus On Work You Love.</p>
         </div>
         <div className='problem-statement'>
            <h3>How do I even IT?</h3>
            <p>Owning and operating a business is intimidating enough. Trying to figure out and keep up with the ever evolving IT makes an optimized business impossible.
            </p>
         </div>
         <div className='problem-statement'>
            <h3>I have no idea how to even get on Google</h3>
            <p>Everyone uses the internet to look up a business before actually going, much like how you're reading my webpage prior to contacting me. Search engines, like Google, index a series of keywords and phrases on your website to determine relevancy of your website to a person's search query. Utilizing keywords and phrases is called SEO (Search Engine Optomization) and can play an intergal part in letting potential customers know you exist.
            </p>
         </div>
         <div className='problem-statement'>
          <h3>How do I get a professional email instead of Gmail, Outlook, etc.?
          </h3>
          <p>It all has to do with how the internet routes web traffic. Getting an email with your website at the end (or with your domain) is a matter of having your website with the capability to send and receive email.
          </p>
         </div>
      </div>
    </div>
  )
}
