import React from 'react'

import '../css/About.css'

export default function About() {
  return (
    <div className='about'>
      <title>About</title>
        <div className='mission-statement'>
          <h2>Mission Statement</h2>
          <p>Laptop Lifestyle's mission is to enable small businesses to focus on their business. We believe with the right application of technology, a person can do the workload of five people.
          </p>
        </div>
        <div className='core-values'>
          <div className='core-values-statement'> 
        <h4>Here at Laptop Lifestyle our core values are:</h4>
        </div>
        <ul>
          
          <li className='core-values-item'><strong>Quality:</strong> Going beyond the requirements document to produce the product the customer is envisioning.
          </li>
          <li className='core-values-item'><strong>Honesty: </strong>Honesty is about communicating the truth in a digestible manner. Honesty builds relaitonships and manages expectations. If a job is outside our skillset, we'll let you know. If a job super simple, we will let to know to help you save money.
          </li>
          <li className='core-values-item'><strong>Transparancy:</strong> We believe in the Keep It Simple (KIS) methodology. A requirements document for the job to product a quiality product at a pre-established price. There are no hidden fees and no behind the scenes secrets. Everything that we do is transparent and upfront to you, the customer.

          </li>
        </ul>
        </div>
    </div>
  )
}
